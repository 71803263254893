<template>
  <section class="cus-wrap">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="角色配置：">
        <el-input v-model="form.role"></el-input>
      </el-form-item>
      <el-form-item label="账号：">
        <el-input v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="密码：">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="用户名称：">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="监测项目：">
        <el-tag type="info" v-for="item in form.monitorItem" :key="item.label">{{item}}</el-tag>
      </el-form-item>
      <el-form-item label="用户状态：">
        <el-switch v-model="form.state"  active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item class="btn-group">
          <el-button @click="goBack">返回</el-button>
          <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import eventHub from '@/utils/eventHub'
export default {
  mounted () {
    eventHub.$emit('msg', '客户详情')
  },
  data () {
    return ({
      form: {
        role: '终端用户',
        account: 'vankeadmin',
        password: '******',
        name: 'vanke',
        email: 'admin@vake.com',
        contact: '15012344321',
        monitorItem: ['万科', '绿城'],
        state: false
      }
    })
  },
  methods: {
    goBack () {
      this.$router.push('/settings/role/cusAccount/')
    },
    onSubmit () {
      this.$router.push('/settings/role/cusAccount/')
    }
  }
}
</script>
<style lang="scss">
  .cus-wrap{
    background: #fff;
    padding: 30px 20px 20px;
    .el-form-item {
      margin-bottom: 0;
      &.btn-group{
        margin: 20px 0;
      }
      .el-input__inner{
        border: none;
      }
      .el-tag{
        font-size: 13px;
        margin: 0 5px;
      }
    }
  }
</style>
